import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";

// const options = {
//     autoConfig: true,
//     debug: false,
//     // Add other properties as needed
// };

// ReactPixel?.init("1687707621632877", options);

const SeeDemoForm = () => {
    //   const [postOrder, { isError, isSuccess }] = usePostOrderMutation();
    const {
        register,
        handleSubmit,
        reset,
        formState: { },
    } = useForm();
    //   const [price, setPrice] = useState(790);


    //   useEffect(() => {
    //     if (isSuccess) {
    //       setShowSuccessToast(true);
    //     }
    //   }, [isSuccess]);

    //   useEffect(() => {
    //     if (isError) {
    //       setShowErrorToast(true);
    //     }
    //   }, [isError]);



    const onSubmit = (data) => {
        if (data?.phone?.includes("01")) {
            fetch('https://16ana.net/thakurgaonexpress/order', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ type: 'Pos', ...data }),
            })
                .then(res => res.json())
                .then(data => {
                    if (data) {
                        toast.success('এপ্লাই সম্পন্ন হয়েছে, ধন্যবাদ।')
                    }

                    // ReactPixel.track("Purchase", {});
                    reset();
                })

            const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
            const number = data?.phone;
            const myNumber = '01310751276';
            const msg = `প্রিয় ${data.name}, Clear Softwares এ ডেমোর জন্য আবেদন করার জন্য আপনাকে ধন্যবাদ, খুব দ্রুতই আমরা আপনার সাথে যোগাযোগ করবো ইনশাল্লাহ। আমাদের Whatsapp-01310751276`;
            const myMsg = `প্রিয় Jahid, জনাব ${data.name}, ডেমোর জন্য আবেদন করেছে ফোন-${data?.phone}`;
            const message = encodeURI(msg);
            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`;
            fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                });
            const url2 = `https://api.greenweb.com.bd/api.php?token=${token}&to=${myNumber}&message=${myMsg}`;
            fetch(url2)
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                });
        } else {
            toast.error("মোবাইল নম্বর সঠিক নয়");
        }
    };

    //   useEffect(() => {
    //     if (showSuccessToast) {
    //       toast.success("আপনার অর্ডার কনফার্ম হয়েছে");
    //       setShowSuccessToast(false);
    //     }
    //   }, [showSuccessToast]);

    //   useEffect(() => {
    //     if (showErrorToast) {
    //       toast.error("আপনার অর্ডার ফেইল হয়েছে");
    //       setShowErrorToast(false);
    //     }
    //   }, [showErrorToast]);

    return (
        <div class="my-4">
            <h3 className='text-center my-4'>ডেমো দেখতে নিচের ফর্মটি পূরণ করে এপ্লাই করুন। আমরা আপনার সাথে যোগাযোগ করবো ইনশাল্লাহ। </h3>
            <h1 class="text-md text-center bg-success p-3 text-white rounded-xl">
                আপনার তথ্য
            </h1>
            <form
                onSubmit={handleSubmit(onSubmit)}
                class="mt-4 d-flex justify-content-center align-items-center flex-column"
            >
                <div class="form-group w-75">
                    <label for="name" class="form-label">
                        আপনার নাম <span class="text-danger">*</span>
                    </label>
                    <input
                        {...register("name")}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="আপনার নাম লিখুন"
                        class="form-control p-3"
                        required
                    />
                </div>
                <div class="form-group w-75">
                    <label for="name" class="form-label mt-2">
                        আপনার দোকান, শোরুমের নাম <span class="text-danger">*</span>
                    </label>
                    <input
                        {...register("shop_name")}
                        type="text"
                        id="shop_name"
                        name="shop_name"
                        placeholder="দোকান, শোরুমের নাম লিখুন"
                        class="form-control p-3"
                        required
                    />
                </div>
                <div class="form-group w-75">
                    <label for="phone" class="form-label mt-2">
                        মোবাইল নং <span class="text-danger">*</span>
                    </label>
                    <input
                        {...register("phone")}
                        type="text"
                        id="phone"
                        name="phone"
                        placeholder="আপনার মোবাইল নং লিখুন"
                        class="form-control p-3"
                        required
                    />
                </div>
                <div class="form-group w-75">
                    <label for="address" class="form-label mt-2">
                        প্রতিষ্ঠানের ঠিকানা <span class="text-danger">*</span>
                    </label>
                    <input
                        {...register("address")}
                        type="text"
                        id="address"
                        name="address"
                        placeholder="আপনার প্রতিষ্ঠানের ঠিকানা লিখুন"
                        class="form-control p-3"
                        required
                    />
                </div>
                <button
                    class="btn btn-lg btn-success text-white my-4 rounded-md"
                    type="submit"
                >
                    ডেমোর জন্য এপ্লাই করুন
                </button>
            </form>
        </div>

    );
};

export default SeeDemoForm;
