import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdFeaturedPlayList } from "react-icons/md";
import { FcAdvance } from "react-icons/fc";
import { GiLevelFourAdvanced } from "react-icons/gi";
import { MdDashboard } from "react-icons/md";
import { FaUserCog } from "react-icons/fa";
import { BiSolidPurchaseTag } from "react-icons/bi";
import { FaProductHunt } from "react-icons/fa6";
import { FaCartShopping } from "react-icons/fa6";
import { MdAccountBalance } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { IoMdSettings } from "react-icons/io";
import SeeDemoForm from './SeeDemoForm';

const Checkout = () => {
    const [width, setWidth] = useState()
    const { serviceId } = useParams();

    const targetRef = useRef(null);

    const handleJumpTo = () => {
        const targetElement = targetRef.current;

        // Check if targetElement is not null before accessing scrollIntoView()
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    useEffect(() => {
        const displayWidth = window.innerWidth;
        setWidth(displayWidth)
    }, [width, setWidth])

    return (
        <div className='container my-5'>
            <h3 style={{ fontFamily: 'Noto Sans Bengali' }} className='my-4 text-center'>{serviceId === '0' ? 'আপনার দোকান বা শোরুমের স্টক, ক্যাশ ও মূলধন হিসাবের স্পষ্টতা নিশ্চিত করুন Clear Pos অনলাইন ইনভেন্টরি ও একাউন্টিং সফটওয়্যার এর মাধ্যমে।' : 'যে কোনো ব্যবসার জন্য অনলাইন অ্যাকাউন্টিং ম্যানেজমেন্ট সফটওয়্যার।'}</h3>
            <div className='my-2 text-center'>
                <button onClick={handleJumpTo} className='btn btn-md btn-success'>ডেমো দেখতে ক্লিক করুন</button>
            </div>
            <div className=''>
                {/* width="560" height="315" */}
                <iframe height={width < 450 ? '250' : '615'} className='w-100' src="https://www.youtube.com/embed/3jFO2NYLBm8?si=z5xsb5vGh1Amo0uN"
                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </div>

            <h3 className='my-4 text-center'>কেন আমাদের সফটওয়্যার সেরা?</h3>
            

            <p><FcAdvance /> খুব সিম্পল ও সবকিছু ক্রমান্বয়ে সাজানো।</p>
            <p><FcAdvance /> আমাদের সফটওয়্যার সর্বাধুনিক টেকনোলজি দ্বারা তৈরীকৃত যার ফলে খুবই ফাস্ট, মনে হবে আপনি অফলাইন সফটওয়্যার ব্যাবহার করছেন।</p>
            <p><FcAdvance /> মোবাইল ফ্রেন্ডলী অর্থাৎ মোবাইলের মাধ্যমে সবকিছু হিসাব দেখতে ও করতে পারবেন।</p>
            <p><FcAdvance /> মোবাইল অ্যাপস প্রদান।</p>
            <p><FcAdvance /> বাংলাদেশের ব্যাবসায়ীদের জন্য বিশেষ করে তৈরি করা হয়েছে অর্থাৎ এক্সট্রা বিদেশি ফিচার এর ঝামেলা নেই।</p>
            <p><FcAdvance /> দীর্ঘ ৫ বছরের ব্যাবসায়িক অভিজ্ঞতা থেকে তৈরি করা হয়েছে আমাদের এই সফটওয়্যার যাতে স্টক ক্যাশ ও মূলধনের হিসাবের সকল সমাধানই রয়েছে।</p>
            <p><FcAdvance /> প্রয়োজনে যে কোনো ধরনের ফিচার যোগ করা বা বাদ দেওয়ার সুবিধা অর্থাৎ আপনি যেমন চাইবেন তেমনি হবে আপনার সফটওয়্যার ।</p>
            <p><FcAdvance /> দোকান বা শোরুমের ধরন অনুযায়ী আলাদা আলাদা ফিচার।</p>
            <p><FcAdvance /> আমরা আপনাকে শুধু সফটওয়্যার দিবো না আমরা নিজে থেকে অভিজ্ঞ হিসাবরক্ষক এর তত্বাবধায়নে আপনার সহযোগীতা সাপেক্ষে আপনার হিসাবকে শতভাগ সচ্ছ করে দেওয়ার চেষ্টা করবো ইনশাআল্লাহ।</p>
            <p><FcAdvance /> অভিজ্ঞ কর্মী দ্বারা সার্বক্ষণিক অনলাইন এবং প্রয়োজনে উপস্থিত হয়ে সবকিছু বুঝিয়ে দেওয়া হয়।</p>
            <div className='my-2 text-center'>
                <button onClick={handleJumpTo} className='btn btn-md btn-success'>ডেমো দেখতে ক্লিক করুন</button>
            </div>
            <h3 className='my-4 text-center'>আপনার প্রয়োজনীয় কিছু গুরুত্বপূর্ণ সুবিধা</h3>
            <p><MdFeaturedPlayList /> বিস্তারিত খরচসহ অটো প্রফিট শীট তৈরি।</p>
            <p><MdFeaturedPlayList /> বাকি সেল বাদ দিয়ে বিস্তারিত প্রফিট শীট তৈরি। </p>
            <p><MdFeaturedPlayList /> যেকোনো সময় ব্যবসার ব্যালেন্স শীট অর্থাৎ লাভসহ মোট মূলধন দেখার সুবিধা। </p>
            <p><MdFeaturedPlayList /> লোন বা কর্জের হিসাব রাখার সুবিধা।</p>
            <p><MdFeaturedPlayList /> ক্রয় বা বিক্রয়ের পর কাস্টমার বা সাপ্লাইয়ারকে এসএমএস পাঠানোর সুবিধা।</p>
            <p><MdFeaturedPlayList /> প্রোডাক্ট কমে গেলে লো স্টক এলার্ট প্রদান।</p>
            <p><MdFeaturedPlayList /> বেশি বিক্রিত ও কম বিক্রিত প্রোডাক্টের রিপোর্ট।</p>
            <p><MdFeaturedPlayList /> কাস্টমার বাকি রিপোর্ট।</p>
            <p><MdFeaturedPlayList /> সাপ্লাইয়ার বাকি রিপোর্ট।</p>
            <p><MdFeaturedPlayList /> পজ মেশিনে কিংবা ম্যানুয়ালি ক্রয় ও বিক্রয় মেমো এন্ট্রির সুবিধা।</p>
            <p><MdFeaturedPlayList /> অটো বারকোড সুবিধা।</p>
            <div className='my-2 text-center'>
                <button onClick={handleJumpTo} className='btn btn-md btn-success'>ডেমো দেখতে ক্লিক করুন</button>
            </div>
            <h3 className='my-4 text-center'>এছাড়াও সকল ফিচারসমূহ</h3>
            <p><MdDashboard /> Dashboard</p>
            <p><FaUserCog /> User Management</p>
            <p><FaProductHunt /> Product Info</p>
            <ul>
                <li>Add And Manage Brand, Unit, Category, Origin.</li>
                <li>Add And Manage Product.</li>
            </ul>
            <p><BiSolidPurchaseTag /> Purchase</p>
            <ul>
                <li>Add And Manage Supplier</li>
                <li>Add Purchase</li>
                <li>Add Pos Purchase</li>
                <li>Manage Purchase</li>
                <li>Manage Purchase Return</li>
                <li>Add Purchase Order</li>
                <li>Manage Purchase Order</li>
            </ul>
            <p><FaCartShopping /> Sales</p>

            <ul>
                <li>Add And Manage Customer</li>
                <li>Add Sales</li>
                <li>Add Pos Sales</li>
                <li>Manage Sales</li>
                <li>Manage Sales Return</li>
            </ul>
            <p><MdAccountBalance /> Accounts Info</p>

            <ul>
                <li>Add and Manage Income Head</li>
                <li>Add and Manage Expense Head</li>
                <li>Add and Manage Bank Accounts</li>
                <li>Add and Manage Loan Accounts</li>
                <li>Add and Manage Opening Capital</li>
            </ul>
            <p><GrTransaction />Transactions</p>
            <ul>
                <li>Add and Manage Income</li>
                <li>Add and Manage Expense</li>
                <li>Add and Manage Collections</li>
                <li>Add and Manage Payment</li>
                <li>Add and Manage Bank Deposit</li>
                <li>Add and Manage Bank Withdraw</li>
                <li>Add and Manage Loan Taken</li>
                <li>Add and Manage Loan Return</li>
            </ul>
            <p><GrTransaction />Transactions</p>
            <ul>
                <li>Daily Cash Statement</li>
                <li>Bank Statement</li>
                <li>Balance Sheet</li>
                <li>Profit Sheet</li>
                <li>Advance Profit Sheet</li>
                <li>Current Stock</li>
                <li>Stock Ledger</li>
                <li>Best and Less Selling Product</li>
                <li>Stock Out Report</li>
                <li>Customer Due Report</li>
                <li>Customer Ledger Report</li>
                <li>Supplier Due Report</li>
                <li>Supplier Ledger Report</li>
                <li>Loan Due Report</li>
                <li>Loan Ledger Report</li>
                <li>Invoice Wise Profit</li>
                <li>Custom Barcode</li>
            </ul>
            <p><IoMdSettings />Settings</p>
            <div ref={targetRef}>
                <SeeDemoForm />
            </div>
        </div>
    );
};

export default Checkout;